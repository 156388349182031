import {
  Suspense,
  lazy,
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

import Layout from "./Layout";
import { isAuthenticated } from "./context";

const Home = lazy(() => import("./pages/Home"));
const EditOwners = lazy(() => import("./pages/EditOwners"));
const EditEmployee = lazy(() => import("./pages/EditEmployee"));
const AddOwners = lazy(() => import("./pages/AddOwners"));
const EditCompany = lazy(() => import("./pages/EditCompany"));
const AddCompany = lazy(() => import("./pages/AddCompany"));
const SyncHubspotOwners = lazy(() => import("./pages/SyncHubspotOwners"));
const RefreshAllCompanies = lazy(() => import("./pages/RefreshAllCompanies"));
const RefreshAllEmployees = lazy(() => import("./pages/RefreshAllEmployees"));
const RotationLogs = lazy(() => import("./pages/RotationLogs"));
const LeadAssignLogs = lazy(() => import("./pages/LeadAssignLogs"));
const ViewOwners = lazy(() => import("./pages/ViewOwners"));
const ViewCompanies = lazy(() => import("./pages/ViewCompanies"));
const ViewRegions = lazy(() => import("./pages/ViewRegions"));
const ViewRegionCompanies = lazy(() => import("./pages/ViewRegionCompanies"));
const EmailComposer = lazy(() => import("./pages/EmailComposer"));
const EditEmailTemplate = lazy(() => import("./pages/EditEmailTemplate"));
const EmailTemplates = lazy(() => import("./pages/EmailTemplates"));
const Login = lazy(() => import("./pages/Login"));
const Logout = lazy(() => import("./pages/Logout"));

//const queryClient = new QueryClient({});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const App = () => {
  const [isLoggedin, setUserLoggedin] = useState(false);

  useEffect(() => {
    console.log("***** validating login ****");
    const authCookie = window.localStorage.getItem("authcookie");
    if (authCookie != null) {
      setUserLoggedin(true);
    } else {
      setUserLoggedin(false);
    }
  }, []);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <isAuthenticated.Provider
        value={{
          isLoggedin,
          setUserLoggedin,
        }}
      >
        <Layout>
          <Router>
            <Suspense fallback={<BeatLoader color="#0d6efd" />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/edit-owners" element={<EditOwners />} />
                <Route path="/edit-employee" element={<EditEmployee />} />
                <Route path="/edit-company" element={<EditCompany />} />
                <Route path="/add-a-company" element={<AddCompany />} />
                <Route path="/add-owners" element={<AddOwners />} />
                <Route path="/view-companies" element={<ViewCompanies />} />
                <Route path="/view-regions" element={<ViewRegions />} />
                <Route
                  path="/view-region-companies/:region"
                  element={<ViewRegionCompanies />}
                />
                <Route path="/view-owners" element={<ViewOwners />} />
                {/*<Route path="/sync-hubspot-owners"  element={<SyncHubspotOwners/>}  />*/}

                <Route
                  path="/refresh-all-companies"
                  element={<RefreshAllCompanies />}
                />
                <Route
                  path="/refresh-all-employees"
                  element={<RefreshAllEmployees />}
                />
                <Route path="/rotation-logs" element={<RotationLogs />} />
                <Route path="/lead-assign-logs" element={<LeadAssignLogs />} />

                <Route path="/email-templates" element={<EmailTemplates />} />
                <Route path="/email-composer" element={<EmailComposer />} />
                <Route
                  path="/edit-email-template"
                  element={<EditEmailTemplate />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
              </Routes>
            </Suspense>
          </Router>
        </Layout>
      </isAuthenticated.Provider>
    </PersistQueryClientProvider>
  );
};

export default App;
