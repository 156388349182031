function Footer() {
  return (
    <div className="App">
      <footer className="text-center">
        <p>
          &copy; 2023 <b>Hubspot Load Rotator</b>
        </p>
      </footer>
    </div>
  );
}

export default Footer;
