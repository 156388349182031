//import { useOutletContext } from 'react-router-dom';
import { React, useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { isAuthenticated } from "./../context";
import Logo from "./../RiverPoolsLog.png";

function Header() {
  const { isLoggedin, setUserLoggedin } = useContext(isAuthenticated);

  return isLoggedin === true ? (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/" title="River Pools Hubspot App">
          <img src={Logo} className="menu-logo" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Manage" id="basic-nav-dropdown">
              <NavDropdown.Item href="/view-companies">
                Dealers
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item href="/view-regions">Regions</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Refresh Data" id="basic-nav-dropdown">
              <NavDropdown.Item href="/refresh-all-companies">
                All Companies
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/refresh-all-employees">
                All Employees
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Logs" id="basic-nav-dropdown">
              <NavDropdown.Item href="/rotation-logs">
                Rotation Logs
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/lead-assign-logs">
                Lead Assign Logs
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Email" id="basic-nav-dropdown">
              <NavDropdown.Item href="/email-templates">
                Templates
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link className="text-right" href="/logout">
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  ) : (
    <div className="m-3">&nbsp;</div>
  );
}

export default Header;
